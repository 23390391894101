import './App.css';

function App() {
  return (
    <div className="App">
      <div className='container' style={{"textAlign": "left"}} >
        <div className="block">
          <h3>aria2c Download</h3>
          <code>
            yt-dlp --no-hls-use-mpegts --external-downloader aria2c --external-downloader-args "aria2c:-x 16 -k 1M" HTTP_URL
          </code>
        </div>
        <div className="block">
          <h3>Bulk download from URLS (from file)</h3>
          <code>
            yt-dlp -a FILENAME.txt -P DIRECTORY
          </code>
        </div>
        <div className="block">
          <h3>Download to directory</h3>
          <code>
            yt-dlp HTTP_URL -P DIRECTORY
          </code>
        </div>
        <div className="block">
          <h3>Download video as MP4 format (with best resolution)</h3>
          <code>
            yt-dlp -f "bestvideo[ext=mp4]+bestaudio[ext=m4a]/best[ext=mp4]/best" "YOUTUBE_URL"
          </code>
        </div>
        <div className="block">
          <h3>Format title of the file</h3>
          <code>
            yt-dlp HTTP_URL -o "%(title)s.%(ext)s"
          </code>
        </div>
        <div className="block">
          <h3>Download segments of video</h3>
          <p className='description'>To download 20 seconds from start time: 00:01:05.00</p>
          <code>
            yt-dlp HTTP_URL --external-downloader ffmpeg --external-downloader-args "ffmpeg_i:-ss 00:01:05.00 -t 00:00:20.00"
          </code>
        </div>
      </div>
    </div>
  );
}

export default App;
